//React imports
import { lazy } from "react";
//Third parties
import { RouteProps } from "react-router-dom";
//Lazy loaded views

/**Aether view */
const HomeView = lazy(() => import("../views/home/home.view"));
const TermsAndConditions = lazy(() => import("../views/termsAndConditions/termsAndConditions.view"));

export const routes: RouteProps[] = [
  {
    path: "/",
    element: <HomeView />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />
  }
];
