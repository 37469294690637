import { createContext, useState } from "react";

interface PageLoadedContextValues {
    isLoaded: () => boolean;
    areEntranceAnimationsDone: () => boolean;
    toggleLoaded: (loaded: boolean) => void;
    toggleEntranceAnimationsDone: (done: boolean) => void;
}

export const PageLoadedContext = createContext<PageLoadedContextValues>({
    isLoaded: () => false,
    toggleLoaded: (_locale: boolean) => {},
    areEntranceAnimationsDone: () => false,
    toggleEntranceAnimationsDone: (_done: boolean) => {}
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PageLoadedProvider = ({ children }: { children: any }): JSX.Element => {
    // State
    const [loaded, setLoaded] = useState<boolean>(false);
    const [entranceAnimationsDone, setEntranceAnimationsDone] = useState<boolean>(false);

    // Methods
    const isLoaded = () => loaded;
    const areEntranceAnimationsDone = () => entranceAnimationsDone;
    const toggleLoaded = (loaded: boolean) => setLoaded(loaded);
    const toggleEntranceAnimationsDone = (done: boolean) => setEntranceAnimationsDone(done);

    return (
        <PageLoadedContext.Provider
            value={{
                isLoaded,
                toggleLoaded,
                areEntranceAnimationsDone,
                toggleEntranceAnimationsDone
            }}
        >
            {children}
        </PageLoadedContext.Provider>
    );
};

export default PageLoadedProvider;