//Third parties
import { BrowserRouter as Router, Routes, Route, Navigate,  } from "react-router-dom"
import { FirestoreProvider, FunctionsProvider, useFirebaseApp } from "reactfire"
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"
//Local imports
import { routes } from "./routes/index"
//Styles
import "./App.scss"

function App() {
  /**Firestore instance */
  const firestoreInstance = getFirestore(useFirebaseApp());
  const functionsInstance = getFunctions(useFirebaseApp());

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <FunctionsProvider sdk={functionsInstance}>
        <Router>
          <Routes>
            {routes.map((route, i) => (
              <Route {...route} key={`public-routes-route-${i}`} />
            ))}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </FunctionsProvider>
    </FirestoreProvider>
  )
}

export default App
