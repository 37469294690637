import { initializeApp } from "firebase/app";

/**Firebase config */
export const firebaseClientConfig = {
  apiKey: "AIzaSyD16Z0xYrjUyPCDmpSQGp-aT8qUj7Xa3FI",
  authDomain: "cashin-prod.firebaseapp.com",
  databaseURL: "https://cashin-prod-default-rtdb.firebaseio.com",
  projectId: "cashin-prod",
  storageBucket: "cashin-prod.appspot.com",
  messagingSenderId: "171276607389",
  appId: "1:171276607389:web:99191c49a5a7dc4a7a9974",
  measurementId: "G-L8WT1WRSEM"
};

export const firebaseProdConfig = {
    apiKey: "AIzaSyD16Z0xYrjUyPCDmpSQGp-aT8qUj7Xa3FI",
    authDomain: "cashin-prod.firebaseapp.com",
    databaseURL: "https://cashin-prod-default-rtdb.firebaseio.com",
    projectId: "cashin-prod",
    storageBucket: "cashin-prod.appspot.com",
    messagingSenderId: "171276607389",
    appId: "1:171276607389:web:99191c49a5a7dc4a7a9974",
    measurementId: "G-L8WT1WRSEM"
};
