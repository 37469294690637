import { createContext, useEffect, useState } from "react";

// Localization .jsons
import Spanish from '../lang/es';
import English from '../lang/en-US';
import { Localization } from "../interfaces/localization";
import { IntlProvider } from "react-intl";

interface LocalizationContextValues {
    getCurrentLocale: () => Localization;
    changeLocale: (locale: Localization) => void;
}

export const LocalizationContext = createContext<LocalizationContextValues>({
    getCurrentLocale: () => "es",
    changeLocale: (_locale: string) => {}
});

const langs: {[key in Localization]: any} = {
    ['en-US']: English,
    es: Spanish
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LocalizationProvider = ({ children }: { children: any }): JSX.Element => {
    // State
    const [currentLocale, setCurrentLocale] = useState<Localization>("es");
    const [currentLang, setCurrentLang] = useState<any>(Spanish);

    // Methods
    const getCurrentLocale = () => currentLocale;
    const changeLocale = (locale: Localization) => {
        setCurrentLocale(locale)
        setCurrentLang(langs[locale]);
    };

    // Initial setup
    useEffect(() => {
        let lang;
        
        // Get locale from browser to establish language to use
        let locale: Localization = navigator.language as Localization;
        if (Object.keys(langs).some((lang) => lang === locale)) {
            lang = langs[locale];
        } else {
            lang = langs.es;
            locale = "es";
        }

        setCurrentLang(lang);
        setCurrentLocale(locale)
    }, []);

    return (
        <LocalizationContext.Provider
            value={{
                getCurrentLocale,
                changeLocale
            }}
        >
            <IntlProvider messages={currentLang} locale={currentLocale} defaultLocale="es">
                {children}
            </IntlProvider>
        </LocalizationContext.Provider>
    );
};

export default LocalizationProvider;